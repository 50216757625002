/* eslint-disable import/order */

import './styles.css';

import { Pagination, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import { LoadingOutlined } from '@ant-design/icons';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import qs from 'qs';
import { useLocation } from 'dva';

const useStyles = createUseStyles({
  date: {
    width: '30%',
    textAlign: 'center',
    fontSize: '12px',
    color: '#777',
  },
  root: {
    width: '100%',
    height: '100%',
  },
  channelItem: {
    // display: 'flex',
    // alignItems: 'center',
    cursor: 'pointer',
    // cursor: 'pointer'
  },
  channelItemSelected: {
    color: '#e6f4f5',
    backgroundColor: '#009398 !important',
  },
});
const SiderContent = ({
  // getMoreChannels,
  channels,
  getChannelBySid,
  initial,
  validChannels,
  role,
  negobot,
  setValidChannels,
  setFormEditable,
  totalItems,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [selectedChannel, setSelectedChannel] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const getActiveChannel = (validChannelsList) => {
    if (validChannelsList.filter((c) => c.default).length) {
      return validChannelsList.filter((c) => c.default)[0];
    }
    return Array.isArray(validChannelsList) && validChannelsList.length > 0
      ? validChannelsList[0]
      : null;
  };
  // const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    const initChannel = async () => {
      const activeChannel = getActiveChannel(validChannels);
      if (selectedChannel) return;
      let defaultChannelId = activeChannel && activeChannel.channel_id;
      if (channels && channels.length > 0 && !defaultChannelId) {
        defaultChannelId = channels[0].sid;
      }
      if (defaultChannelId) {
        setSelectedChannel(defaultChannelId);
        await getChannelBySid(defaultChannelId);
      }
    };
    initChannel();
  }, [initial, channels, getChannelBySid, selectedChannel, validChannels]);
  const onSelectChannelHandler = useCallback(
    async (sid) => {
      setSelectedChannel(sid);
      setFormEditable(false);
      await getChannelBySid(sid);
    },
    [getChannelBySid, setFormEditable],
  );

  const getMoreSession = async (page) => {
    setLoading(true);
    const responsedata = await negobot.getMoreSession({
      authToken: params.token,
      history: true,
      buyer_id: params.buyer_id,
      page,
    });
    setLoading(false);
    setValidChannels(responsedata);
  };
  return (
    <>
      <Pagination
        simple
        defaultCurrent={1}
        onChange={(page) => {
          getMoreSession(page);
        }}
        pageSize={50}
        total={totalItems}
      />
      {loading ? (
        <div>Loading...</div>
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="List"
              height={height}
              itemCount={channels.length}
              itemSize={120}
              width={width}
            >
              {({ index, style }) => (
                <div className={index % 2 ? 'ListItemOdd' : 'ListItemEven'} style={style}>
                  <ListItem
                    alignItems="flex-start"
                    className={classes.channelItem}
                    selected={selectedChannel === channels[index].channel_id}
                    onClick={() => onSelectChannelHandler(channels[index].channel_id)}
                    key={channels[index].channel_id}
                  >
                    <ListItemText
                      primary={channels[index].rfq_number}
                      secondary={
                        <>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {role === 'ROLE_BUYER'
                              ? `${channels[index].products
                                  .map((p) => `${p.product_info.name}-${p.tenant.name}`)
                                  .join(',')}`
                              : `${channels[index].products
                                  .map(
                                    (p) =>
                                      `${p.product_info.name}-${channels[index].customer.name}`,
                                  )
                                  .join(',')}`}
                          </Typography>
                          <ListItemText>
                            <span className={classes.date}>
                              {moment(channels[index].updated_date).format('lll')}
                            </span>
                          </ListItemText>
                        </>
                      }
                    />
                  </ListItem>
                </div>
              )}
            </List>
          )}
        </AutoSizer>
      )}
    </>
  );
};

export default React.memo(SiderContent);
