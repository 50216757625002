import React from 'react';
import { Table } from 'antd';
import useStyles from './styles';

const StandardNegotiateTable = (props) => {
  const classes = useStyles();
  const { data, rowKey, ...rest } = props;
  return (
    <div>
      <Table
        className={classes.standardTable}
        scroll={{ y: 240 }}
        size="middle"
        rowKey={rowKey || 'key'}
        dataSource={data}
        pagination={false}
        {...rest}
      />
    </div>
  );
};

export default StandardNegotiateTable;
