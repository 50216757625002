import PropTypes from 'prop-types';

/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-use-before-define */

/**
 * Channel - Wrapper component for a channel. It needs to be place inside of the Chat component.
 * MessageList, MessageInput should be used as children of the Channel component.
 *
 */
const Channel = ({ channel, render }) => {
  console.log('Channel getAttributes errorchannel:', channel);

  // console.log('Channel render:', channel);
  return render({
    messages: (channel || {}).session,
  });
};

Channel.propTypes = {
  /**
   * The current channel this message is displayed in
   */
  channel: PropTypes.object.isRequired,
  /**
   * Using the render props to make the channel more flexible
   */
  render: PropTypes.func.isRequired,
};

export default Channel;
