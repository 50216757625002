import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';

/**
 * Chat - Wrapper component for Chat. The needs to be placed around any other chat components.
 * This Chat component provides the chat context to all other components.
 *
 * The chat context provides the following props:
 *
 * - client (the client connection)
 * - channels (the list of channels)
 * - setActiveChannel (a function to set the currently active channel)
 * - activeChannel (the currently active channel)
 *
 */
const Chat = ({ client, render, validChannels }) => {
  const [channel, setChannel] = useState(null);

  const getChannelBySid = useCallback(
    async (channelSid) => {
      try {
        const data = validChannels.find((s) => s.channel_id === channelSid);
        setChannel(data);
      } catch (ex) {
        setChannel(null);
        console.log('Chat getChannelBySid error:', ex);
      }
    },
    [validChannels],
  );
  return render({
    channels: validChannels,
    getChannelBySid,
    channel,
    client,
  });
};

Chat.propTypes = {
  /**
   * A Twilio Client object.
   */
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
  /**
   * Using the render props to make the chat more flexible
   */
  render: PropTypes.func.isRequired,
  /**
   * Initial info for the chat instance
   */
  // eslint-disable-next-line react/forbid-prop-types
  initial: PropTypes.object,
};

Chat.defaultProps = {
  initial: undefined,
};

export default React.memo(Chat);
