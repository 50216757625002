import React from 'react';
import { router, routerRedux } from 'dva';
import Home from '../pages/Home';

const { ConnectedRouter } = routerRedux;
const { Switch, Route } = router;

const App = ({ history }) => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </ConnectedRouter>
  );
};

export default App;
