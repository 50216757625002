/* eslint-disable import/order */

import * as config from '../../configs';

import { Layout, MainContent, SiderContent } from './components';
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import Chat from './chat';
import { LoadingOutlined } from '@ant-design/icons';
// import Negobot from '@negobot/nego-chat-sdk';
import Negobot from 'nego-chat-sdk-v1';
import { Spin } from 'antd';
import qs from 'qs';
import { useLocation } from 'dva';
import useStyles from './styles';

const Home = () => {
  const classes = useStyles();
  const [client, setClient] = useState(undefined);
  const [loading, setLoading] = useState({ isLoading: true, tip: '' });
  const [initial, setInitial] = useState(null);
  const [negobotClient, setNegobotClient] = useState(null);
  const [validChannels, setValidChannels] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  const [formEditable, setFormEditable] = useState(false);
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  const {
    api_key,
    tenant_id,
    tenant_name,
    tenant_type,
    customer_id,
    customer_name,
    product_id,
    token,
    role,
    product_name,
    history,
    product_supplier_id,
    buyer_id,
    supplier_id,
  } = params;

  const onInitExtraParams = () => {
    const data = {
      tenant_id,
      product_id,
      customer_name,
      customer_email: customer_id,
      text: 'Product Enquiry',
    };
    if (data) {
      try {
        setInitial(data);
      } catch (ex) {
        console.log('onInitExtraParams error', ex);
      }
    }
  };

  useEffect(() => {
    const initSdk = async () => {
      const apiUrl = config.default.API_URL;

      const negobot = new Negobot(apiUrl);
      setNegobotClient(negobot);
      setLoading({
        isLoading: true,
        tip: 'Initializing the negotiation session...',
      });
      const chat = await negobot.getChat({
        api_key,
        tenant_id,
        tenant_name,
        tenant_type,
        customer_id,
        customer_name,
        product_id,
        token,
        product_name,
        history,
        product_supplier_id,
        buyer_id,
        supplier_id,
      });
      if ((chat.negoValidChannels || []).errors.length) {
        setClient(null);
        setLoading({
          isLoading: true,
          message: chat.negoValidChannels.errors.map((e) => e.message).join(','),
          tip: `Fail to init the negotiation, please contact the negobot admin!`,
        });
        return;
      }
      if (chat) {
        console.log('chat-valid-channels: ', chat);
        setValidChannels((chat.negoValidChannels || {}).validChannels);
        setTotalItems((chat.negoValidChannels || {}).totalItems);
        setClient(chat);
        setLoading({
          isLoading: false,
          tip: '',
        });
      } else {
        setClient(null);
        setLoading({
          isLoading: true,
          tip: 'Fail to init the negotiation, please contact the negobot admin!',
        });
      }
    };
    initSdk();
    const initExtraParams = () => {
      window.addEventListener('message', onInitExtraParams, false);
    };
    initExtraParams();
  }, [api_key, tenant_id, tenant_name, tenant_type, customer_id, customer_name, product_id]);
  return (
    <div className={classes.root}>
      {loading.isLoading ? (
        <div className={classes.center}>
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            tip={
              <>
                <span>{loading.tip}</span>
                <br />
                <strong>{loading.message}</strong>
              </>
            }
          />
        </div>
      ) : (
        <Chat
          className={classes.chat}
          initial={initial}
          validChannels={validChannels}
          client={client}
          render={({ getChannelBySid, loadingChannel, channel }) => {
            return (
              <Layout
                siderContent={SiderContent}
                mainContent={MainContent}
                client={client}
                channels={validChannels}
                getChannelBySid={getChannelBySid}
                loadingChannel={loadingChannel}
                channel={channel}
                initial={initial}
                validChannels={validChannels}
                setValidChannels={setValidChannels}
                formEditable={formEditable}
                setFormEditable={setFormEditable}
                role={role || ''}
                negobot={negobotClient}
                totalItems={totalItems}
              />
            );
          }}
        />
      )}
    </div>
  );
};

export default Home;
