import { Col, Row, Typography } from 'antd';

import React from 'react';
import { getParamLabelAndUnit } from '../utils/utils';
import useStyles from './styles';

const ProcurementParameters = ({ value, session, schema, param }) => {
  const classes = useStyles();
  return (
    <Row className={classes.parameters}>
      {Array.isArray(value) &&
        value.map((p) => {
          const parameter = getParamLabelAndUnit(p.name, schema, param);
          return (
            <Row key={p.name} style={{ marginLeft: '8px', marginTop: '7px' }}>
              {session ? (
                <>
                  <Col span={14} style={{ paddingTop: '1px' }}>
                    <p>{parameter.label}</p>
                  </Col>
                  <Col span={10} style={{ paddingTop: '1px' }}>
                    <p className={classes.parameterValue}>
                      {' '}
                      {`${parameter.type === 'checkbox' ? Boolean(p.value) : p.value} ${
                        parameter.unit
                      }`}
                    </p>
                  </Col>
                </>
              ) : (
                <>
                  <Col span={6}>
                    <Typography>{parameter.label}</Typography>
                  </Col>
                  <Col span={6}>
                    <Typography className={classes.parameterValue}>
                      {' '}
                      {`${parameter.type === 'checkbox' ? Boolean(p.value) : p.value} ${
                        parameter.unit
                      }`}
                    </Typography>
                  </Col>
                  <Col span={12} />
                </>
              )}
            </Row>
          );
        })}
    </Row>
  );
};

export default ProcurementParameters;
