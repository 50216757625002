import { notification } from 'antd';

export const getShortForm = (name) => {
  const newName = name || 'U';
  const matches = newName && newName.match(/\b(\w)/g);
  return matches ? matches.join('') : newName;
};

export const openNotification = (errors, type = 'error') => {
  (errors || []).map((e) =>
    notification[type]({
      message: e.property ? e.property : 'Error',
      description: e.message,
    }),
  );
};

export const getParamLabelAndUnit = (name, schema, parameter) => {
  const param = schema.find((s) => s.name === name);
  if (!param) {
    return { label: '', min: 0, max: 0, unit: '', step: 0, type: 'number' };
  }
  const { parameters: procurementParam } = param;
  if (!procurementParam.value) {
    return { label: param.label, min: 0, max: 0, unit: '', step: 0, type: param.type };
  }
  const { unit, step } = procurementParam.value;
  const rfqParam = parameter.find((p) => p.name === name);
  const [min, max] = rfqParam.value;
  return { label: param.label, min, max, unit, step, type: param.type };
};

export const getPriceAndQuantity = (prop, parameter) => {
  const params = [
    'baseline_price',
    'baseline_quantity',
    'payment_terms',
    'contract_terms',
    'on_demand_delivery',
  ];
  const param = params.includes(prop);
  // && parameter[`${prop}`];
  console.log(parameter, 'parameter****');
  console.log(param, 'param****');
  if (!param) {
    return {
      label: 'Total Price:',
      value: `${Number(parameter.total).toFixed(2)}`,
    };
  }
  const propTypes = ['payment_terms', 'contract_terms', 'on_demand_delivery'];
  if (propTypes.indexOf(prop) >= 0) {
    return {
      label: (parameter.rfq_offer.rfq_parameters.find((pk) => pk.name === prop) || {})?.label,
      // prop === params[0]
      //   ? `Price (${parameter.rfq_offer.rfq_items[0].currency} per ${parameter.rfq_offer.rfq_items[0].uom}):`
      //   : `Order Quantity (${parameter.rfq_offer.rfq_items[0].uom}):`,
      value: (parameter.rfq_offer?.rfq_parameters?.find((pk) => pk.name === prop) || {})?.value,
    };
  }
  return {
    label:
      prop === params[0]
        ? `Price (${parameter.rfq_offer.rfq_items[0].currency} per ${parameter.rfq_offer.rfq_items[0].uom}):`
        : `Order Quantity (${parameter.rfq_offer.rfq_items[0].uom}):`,
    value: Number(parameter.rfq_offer.rfq_items[0][prop]).toFixed(2),
  };
};

export default { getShortForm, getParamLabelAndUnit, openNotification };
