import { Layout as AntdLayout } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import useStyles from './styles';

const renderLayoutComp = (Comp, parentProps) => {
  console.log(parentProps, 'parentProps**********************8');
  return <Comp {...parentProps} />;
};

const { Content, Sider } = AntdLayout;
const Layout = ({ siderContent, mainContent, ...parentProps }) => {
  const classes = useStyles();
  return (
    <AntdLayout className={classes.root}>
      <Sider breakpoint="md" collapsible collapsedWidth={0}>
        {renderLayoutComp(siderContent, parentProps)}
      </Sider>
      <AntdLayout>
        <Content className="innerContent">{renderLayoutComp(mainContent, parentProps)}</Content>
      </AntdLayout>
    </AntdLayout>
  );
};

Layout.propTypes = {
  siderContent: PropTypes.elementType.isRequired,
  mainContent: PropTypes.elementType.isRequired,
};

export default React.memo(Layout);
