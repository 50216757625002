import 'antd/dist/antd.less';

import { Button, Form, Input, Table } from 'antd';

import React from 'react';
import useStyles from './styles';

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} key={index} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);

const NegotiationInputTable = ({ comp, data, col, page, row }) => {
  const classes = useStyles();
  return (
    <Table
      components={comp}
      className={classes.standardTable}
      dataSource={data}
      columns={col}
      scroll={{ y: 240 }}
      size="middle"
      pagination={page}
      rowKey={row || 'id'}
    />
  );
};

class EditableCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: true,
    };
  }

  toggleEdit = () => {
    let { editing } = this.state;
    editing = !editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  round = (num, precision) => {
    return Number(`${Math.round(Number(`${num}e+${precision}`))}e-${precision}`);
  };

  roundNearest = (value, min, max, multiplier) => {
    const num = Math.round(value / multiplier) * multiplier;
    // eslint-disable-next-line no-nested-ternary
    return this.round(num > max ? max : num < min ? min : num, 2);
  };

  save = (e) => {
    const { record, handleSave, dataIndex } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      // this.toggleEdit();
      this.form.setFieldsValue({
        [dataIndex]: this.roundNearest(
          e.currentTarget.value,
          0,
          Math.round(e.currentTarget.value) * 10,
          record.step_count[dataIndex.split('_')[1].trim()],
        ),
      });
      handleSave({ ...record, ...values });
    });
  };

  roundToTwo = (num) => {
    return +`${Math.round(`${num}e+2`)}e-2`;
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;

    return editing ? (
      <>
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                message: `Enter value in steps of ${
                  record.step_count ? record.step_count[dataIndex.split('_')[1].trim()] : ''
                }`,
                validator: (_, value, cb) => {
                  const calculatedvalue = value / record.step_count[dataIndex.split('_')[1].trim()];
                  return Number.isInteger(this.roundToTwo(calculatedvalue)) ? cb() : cb(true);
                },
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              // eslint-disable-next-line no-return-assign
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
            />,
          )}
        </Form.Item>
      </>
    ) : (
      <>
        <Button
          aria-hidden
          style={{
            paddingRight: 24,
            cursor: 'pointer',
            border: '1px solid',
            borderRadius: '4px',
            width: '100%',
          }}
          onClick={this.toggleEdit}
        >
          {children}
        </Button>
      </>
    );
  };

  render() {
    // prettier-ignore
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <>
        <td {...restProps}>
          {editable ? (
            <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
          ) : (
            children
          )}
        </td>
      </>
    );
  }
}

class EditableTable extends React.Component {
  handleSave = (row) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    console.log('save there ');
    const { data, rowKey } = this.props;
    const newData = [...data];
    const key = rowKey || 'key';
    const index = newData.findIndex((item) => row[key] === item[key]);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    const { onSave } = this.props;
    console.log('save there ', newData);

    if (onSave) {
      onSave(newData);
    }
  };

  render() {
    const { data, headers, pagination, rowKey } = this.props;
    // this.columns = this.props.headers;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const columns = headers.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    const negotiationInputTable = (
      <NegotiationInputTable
        comp={components}
        data={data}
        col={columns}
        page={pagination}
        row={rowKey}
      />
    );
    return negotiationInputTable;
  }
}

const EditableFormNegotiate = Form.create()(EditableTable);
export default EditableFormNegotiate;
