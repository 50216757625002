/* eslint-disable import/order */

import Channel from '../Channel';
import Layout from './Layout';
import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import { Spin } from 'antd';
import useStyles from './styles';

const MainContent = ({
  client,
  loadingChannel,
  channel,
  user,
  initial,
  validChannels,
  role,
  setValidChannels,
  setFormEditable,
  formEditable,
  negobot,
}) => {
  console.log(
    loadingChannel,
    channel,
    'loadingChannel, channel, user, initial, validChannels, role',
  );
  const classes = useStyles();
  let content = null;
  if (!channel && !(((channel || {}).session || {}).offers || [])) {
    const indicator = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    content = (
      <div className={classes.spin}>
        <Spin indicator={indicator} />
      </div>
    );
  } else {
    content = (
      <Channel
        channel={channel}
        role={role}
        render={({
          getMessages,
          loadingMessages,
          messages,
          sendMessage,
          getMoreMessages,
          attributes,
          friendlyName,
          paginator,
        }) => (
          <Layout
            sid={(channel || {}).channel_id}
            rfqId={((channel || {}).rfq || {}).id}
            channelData={channel}
            initial={initial}
            user={user}
            client={client}
            getMessages={getMessages}
            loadingMessages={loadingMessages}
            messages={messages}
            sendMessage={sendMessage}
            getMoreMessages={getMoreMessages}
            friendlyName={friendlyName}
            attributes={attributes}
            paginator={paginator}
            validChannels={validChannels}
            setValidChannels={setValidChannels}
            formEditable={formEditable}
            setFormEditable={setFormEditable}
            role={role}
            negobot={negobot}
          />
        )}
      />
    );
  }
  return <div className={classes.root}>{content}</div>;
};

export default MainContent;
