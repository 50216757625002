import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  drawer: {
    padding: '0 24px',
  },
  drawerComment: {
    marginBottom: '0',
    ':global(.ant-list-footer)': {
      position: 'sticky',
      bottom: 0,
      width: '100%',
      background: 'white',
    },
  },
  date: {
    padding: '5px',
    width: '30%',
    textAlign: 'center',
    fontSize: '12px',
    color: '#777',
  },
  commentDate: {
    fontSize: '12px',
  },
  botReply: {
    padding: '10px !important',
    backgroundColor: '#b7e3d4',
    borderRadius: '0px 6px 6px',
  },
  botNextReply: {
    marginTop: '10px',
    marginLeft: '20px',
  },
  userReply: {
    float: 'right',
  },
  chatBox: {
    marginTop: '5px',
    marginBottom: '10px',
  },
  chatBoxHistory: {
    backgroundColor: '#e0e0e063',
    pointerEvents: 'none',
  },
  botIcon: {
    paddingRight: '10px',
    width: '100%',
  },
  userIcon: {
    paddingLeft: '10px',
    width: '100%',
  },
  cancelButton: {
    paddingRight: '10px',
  },
  standardTable: {
    '& thead > tr > th': {
      backgroundColor: '#98d7c2',
    },
  },
  editableCellValueWrap: {
    cursor: 'pointer',
    border: '1px solid',
    borderRadius: '4px',
    padding: '10px 0',
  },
  commentButton: {
    backgroundColor: '#4AB1BD',
    color: 'white',
    borderColor: '#4AB1BD',
  },
  tableClass: {
    color: 'red',
    '& tr:nth-child(n) td': {
      backgroundColor: 'red',
    },
  },
});

export default useStyles;

// .drawer {
//     :global(.ant-drawer-body) {
//       padding: 0 24px;
//     }
//   }

//   .drawerComment {
//     :global(.ant-form-item) {
//       margin-bottom: 0;
//     }

//     :global(.ant-list-footer) {
//       position  : sticky;
//       bottom    : 0;
//       width     : 100%;
//       background: white;
//     }
//   }

//   .date {
//     font-size: 12px;
//   }

//   .botReply {
//     padding         : 1000px !important;
//     background-color: @secondary-color;
//     border-radius   : 0px 6px 6px;
//   }

//   .botNextReply {
//     margin-top : 10px;
//     margin-left: 20px;
//   }

//   .userReply {
//     float: right;
//   }

//   .chatBox {
//     margin-bottom: 10px;
//   }

//   .chatBoxHistory {
//     background-color: #e0e0e063;
//     pointer-events  : none;
//   }

//   .botIcon,
// .cancelButton {
//   padding-right: 10px;
// }

// .userIcon {
//   padding-left: 10px;
// }

// .botIcon,
// .userIcon {
//   width: 100%;
// }
