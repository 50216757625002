import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  avatar: {
    marginRight: 24,
  },
  title: {
    marginRight: 12,
    maxWidth: 300,
  },
  order: {
    maxWidth: 300,
    justifyContent: 'flex-end',
  },
  root: {
    width: '100%',
    height: '100%',
    // '& div': {
    //   overflow: 'hidden',
    //   position: 'relative',
    // },
    '@global': {
      '.ant-layout-content': {
        // margin: [12, 12, 0, 12],
        padding: [0, 12],
        background: '#fff',
      },
      '.ant-layout-footer': {
        padding: [12, 12],
        background: '#fff',
      },
      '.ant-layout-header': {
        position: 'fixed',
        zIndex: 999,
        display: 'block',
        width: '100%',
      },
      '.ReactVirtualized__Grid': {
        outline: 'none',
        position: 'absolute !important',
        overflow: 'hidden !important',
        overflowY: 'scroll !important',
        top: 0,
        bottom: 0,
        left: 0,
        right: -17,
        width: 'auto !important',
      },
    },
  },
  channelInfoContainer: {
    display: 'flex',
    width: '100%',
    '& .channelInfo': {
      flex: 'auto',
    },
  },
  floatRight: {
    float: 'right',
  },
  commentItem: {
    ':global(.ant-list-items)': {
      maxHeight: '200px',
      overflowY: 'auto',
      marginRight: 0,
      width: '22vw',
    },
    ':global(.ant-form-item)': {
      marginBottom: 0,
    },
    ':global(.ant-list-header)': {
      borderBottom: 'none',
      paddingTop: 0,
      span: {
        marginRight: 0,
        float: 'right',
        marginBottom: '10px',
      },
    },
  },
  commentIcon: {
    marginLeft: '10px',
    color: '#4AB1BD',
  },
  date: {
    width: '30%',
    textAlign: 'center',
    fontSize: '12px',
    color: '#777',
  },
  commentDate: {
    fontSize: '12px',
  },
  confirmButton: {
    marginRight: '10px',
    backgroundColor: '#4AB1BD',
    color: 'white',

    borderColor: '#4AB1BD',
  },
  commentButton: {
    backgroundColor: '#4AB1BD',
    color: 'white',
    borderColor: '#4AB1BD',
  },
  negotiateButton: {
    marginRight: '10px',
    backgroundColor: '#4050B5',
    color: 'white',
    borderColor: '#4050B5',
  },
  exitButton: {
    backgroundColor: '#F50357',
    color: 'white',
    borderColor: '#F50357',
  },
  standardTable: {
    '& thead > tr > th': {
      backgroundColor: '#98d7c2',
    },
  },
});

export default useStyles;
