import { Alert } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import React from 'react';

const ErrorResult = (props) => {
  const { errors, title } = props;
  return errors && errors.length > 0 ? (
    <div className="desc">
      <Alert
        message={title || 'Submission Failed'}
        description={errors.map((item) => (
          <>
            <CloseCircleOutlined style={{ color: 'red' }} key={item.property} />
            <span>{item.property && `${item.property}:`}</span>
            <span>{item.message}</span>
          </>
        ))}
        type="error"
        closable
      />
    </div>
  ) : null;
};

export default ErrorResult;
