import config from './config';
import configdev from './config.dev';
import configstaging from './config.staging';

const evnConfig = {
  dev: configdev,
  staging: configstaging,
};

const env = process.env.REACT_APP_ENV;
const configInfo = {
  ...config,
  ...evnConfig[env],
};

export default configInfo;
