import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    height: '100vh',
    '@global': {
      '.ant-layout-sider-children': {
        // overflow: 'auto',
        background: '#fff',
        borderRight: '1px solid #e8e8e8',
      },
      '.ant-layout-header': {
        display: 'flex',
        padding: [12, 12],
        background: '#fff',
        lineHeight: 'initial',
        borderBottom: '1px solid #f0f2f5',
        height: 'auto',
      },
    },
    innerContent: {
      marginTop: 65,
    },
  },
});

export default useStyles;
