import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
    padding: [0, 0],
  },
  layout: {
    width: '100%',
    height: '100%',
    '@global': {
      '.ant-layout-content': {
        margin: [12, 12, 0, 12],
        padding: [0, 12],
        background: '#fff',
      },
      '.ant-layout-footer': {
        padding: [12, 12],
      },
      '.ReactVirtualized__Grid': {
        outline: 'none',
      },
    },
  },
  spin: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
});

export default useStyles;
